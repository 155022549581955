import React from "react";
import { Icon } from "@blueprintjs/core";
import Dialog from "@mui/material/Dialog";
// import "./s&n-InfoBar.scss";
import "../../InfoBar/InfoBar.scss"
import { useIntl } from "react-intl";
import { Link, generatePath, useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../../store/plans/selectors";

function StrengthAndNeedsInfoBar() {
  const [infoexpanded, infosetExpanded] = React.useState<string | false>(false);
  const [infoDocument, setInfoDocument] = React.useState<string | false>(false);

  const intl = useIntl();
  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const history = useHistory();

  const infohandleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    infosetExpanded(isExpanded ? panel : false);
  };

  const [infobaropen, infoBarOpen] = React.useState(false);

  const infoBarClickOpen = () => {
    infoBarOpen(true);
  };

  const infoBarClose = () => {
    infoBarOpen(false);
    infosetExpanded(false);
    setInfoDocument(false);
  };
console.log("texst")
  return (
    <>
      <button onClick={infoBarClickOpen}>
        <span className="info-icon">i</span> Info
      </button>
      <Dialog
        open={infobaropen}
        onClose={infoBarClose}
        className="sidebar-modal small-modal"
      >
        <div className="sidepanel">
          <div className="sidepanel__header">
            <h3>
              {intl.formatMessage({
                id: "app.titles.information.resources.strategic-plan",
              })}
            </h3>
            <button onClick={infoBarClose}>
              <Icon icon="cross" iconSize={12} />
            </button>
          </div>
          <div className="sidepanel__content">
            <div className="info-list">
              <ul>
                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-workspace"}))}>
                  <span>
                  <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    </span>
                    {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-workspace"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-exploring"}))}>
                  <span>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    </span>
                    {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-exploring"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-assessing-root-cause"}))}>
                  <span>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    </span>
                    {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-assessing-root-cause"})}
                  </a>
                </li>
           
                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-type-of-data-alongside"}))}>
                  <span>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    </span>
                    {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-type-of-data-alongside"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-communicating-data"}))}>
                  <span>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    </span>
                    {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-communicating-data"})}
                  </a>
                </li>
              </ul>

              {/* <div className="teamPopup_footer">
                <button>
                  <svg className="calenderIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M16.8428 2.24868H14.6019V0.575684H13.4814V2.24868H4.51778V0.575684H3.39733V2.24868H1.15642C1.00786 2.24868 0.865299 2.30744 0.760258 2.412C0.655217 2.51656 0.596191 2.65847 0.596191 2.80635V16.748C0.596191 16.8959 0.655217 17.0378 0.760258 17.1424C0.865299 17.2469 1.00786 17.3057 1.15642 17.3057H16.8428C16.9913 17.3057 17.1339 17.2469 17.2389 17.1424C17.344 17.0378 17.403 16.8959 17.403 16.748V2.80635C17.403 2.65847 17.344 2.51656 17.2389 2.412C17.1339 2.30744 16.9913 2.24868 16.8428 2.24868ZM3.39733 3.36402V4.47935H4.51778V3.36402H13.4814V4.47935H14.6019V3.36402H16.2826V5.59468H1.71665V3.36402H3.39733ZM1.71665 16.1903V6.71002H16.2826V16.1903H1.71665Z" fill="#155B98" />
                    <path d="M3.11768 8.66162H5.35858V9.77695H3.11768V8.66162Z" fill="#155B98" />
                    <path d="M6.29297 8.66162H8.53388V9.77695H6.29297V8.66162Z" fill="#155B98" />
                    <path d="M9.46484 8.66162H11.7058V9.77695H9.46484V8.66162Z" fill="#155B98" />
                    <path d="M12.6421 8.66162H14.883V9.77695H12.6421V8.66162Z" fill="#155B98" />
                    <path d="M3.11768 10.8926H5.35858V12.0079H3.11768V10.8926Z" fill="#155B98" />
                    <path d="M6.29297 10.8926H8.53388V12.0079H6.29297V10.8926Z" fill="#155B98" />
                    <path d="M9.46484 10.8926H11.7058V12.0079H9.46484V10.8926Z" fill="#155B98" />
                    <path d="M12.6421 10.8926H14.883V12.0079H12.6421V10.8926Z" fill="#155B98" />
                    <path d="M3.11768 13.123H5.35858V14.2384H3.11768V13.123Z" fill="#155B98" />
                    <path d="M6.29297 13.123H8.53388V14.2384H6.29297V13.123Z" fill="#155B98" />
                    <path d="M9.46484 13.123H11.7058V14.2384H9.46484V13.123Z" fill="#155B98" />
                    <path d="M12.6421 13.123H14.883V14.2384H12.6421V13.123Z" fill="#155B98" />
                  </svg> 
                  <span 
                  className="linkView" 
                   onClick={()=>history.push(
                    generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_sci_' : 'sclt_sci_'}calendar` as any),)}
                  >{intl.formatMessage({id:"app.titles.go-to-sci-calender"})}</span></button>

                <button 
                 onClick={()=>history.push(
                  generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_resources' : 'sclt_resources'}` as any),)}
                ><Icon iconSize={18} icon="layers" /> 
                <span 
                className="linkView" 
                >{intl.formatMessage({id:"app.titles.resources"})} </span></button>
              </div> */}
            </div>
          </div>
        </div>

        {
          infoDocument ===
          intl.formatMessage({
            id: "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>{" "}
                  {intl.formatMessage({
                    id:
                      "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals-content" },
                  {
                    p: (chunks) => <p>{chunks}</p>,
                    b: (chunks) => <b>{chunks}</b>,
                    i: (chunks) => <i>{chunks}</i>,
                    br: <br />,

                  })}
              </div>
            </div>
          )}

        {
          infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)-content" },
                  {
                    p: (chunks) => <p>{chunks}</p>,
                  })}
              </div>
            </div>
          )}

        {
          infoDocument === intl.formatMessage({
            id: "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
   }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.strategic-plan.infobar.goals.Setting-Indicator-Targets-content" }
                  , {
                    p: (chunks) => <p>{chunks}</p>,
                  })}
              </div>
            </div>
          )}
{/* new-5 */}
{
          infoDocument ===
          intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-communicating-data"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-communicating-data"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-communicating-data-content"})}}>
              </div>
            </div>
          )}
{/* new-4 */}
          {
          infoDocument ===
          intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-type-of-data-alongside"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-type-of-data-alongside"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-type-of-data-alongside-content"})}}>
              </div>
            </div>
          )}
{/* new-1 */}
{
          infoDocument ===
          intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-workspace"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-workspace"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-workspace-content"})}}>
              </div>
            </div>
          )}
{/* new-2 */}

{
          infoDocument ===intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-exploring"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-exploring"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-exploring-content"})}}>
               </div>
            </div>
        )}
{/* new-3 */}
{
          infoDocument ===intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-assessing-root-cause"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:"app.titles.overview-of-strengths-and-needs-assessing-root-cause"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:  "app.titles.overview-of-strengths-and-needs-assessing-root-cause-content"})}}>
               
              </div>
            </div>
          )}    



      </Dialog>
    </>
  );
}

export default StrengthAndNeedsInfoBar;
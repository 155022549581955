import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Button, Classes, Dialog, HTMLTable, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { getFullName } from "../../../../helpers/message-format-utils";
import { useBeforeBeginningDialog } from "../useBeforeBeginningDialog";
import { SelectorOptionType, UserModel } from "../../../../types";

type OwnProps = {
  openBeforeBeginDialog: boolean;
  OnopenBeforeBeginDialog: () => void;
  selectedSurveyDeployment?: SelectorOptionType;
};

type bookmarkStatuses = {
  user: UserModel;
  isDistrictPerson: boolean;
  bookmarksStarted: boolean;
};

type districtTeamBookmarkStatuses = {
  team: bookmarkStatuses[];
  district: bookmarkStatuses[];
};

type Props = OwnProps;

const BeforeBeginningDialog: FunctionComponent<Props> = (props) => {
  const {
    OnopenBeforeBeginDialog,
    openBeforeBeginDialog,
    selectedSurveyDeployment,
  } = props;
  const {
    show,
    onClose,
    onOpen,
    beforeBeginningDialog: { statuses },
  } = useBeforeBeginningDialog({});

  const intl = useIntl();

  const bookmarkStatuses = useMemo(() => {
    let bookmarkStatuses: districtTeamBookmarkStatuses = {
      team: [],
      district: [],
    };
    bookmarkStatuses.team = statuses.filter((m) => !m.isDistrictPerson);
    bookmarkStatuses.district = statuses.filter(
      (m) => m.bookmarksStarted && m.isDistrictPerson
    );
    return bookmarkStatuses;
  }, [statuses]);

  useEffect(() => {
    if (openBeforeBeginDialog) {
      onOpen(); // call the function for open the dialog box
    }
  }, [openBeforeBeginDialog]);

  const handleDialogClose = () => {
    onClose();
    OnopenBeforeBeginDialog(); //call the function for change the state in needspage
  };

  return (
    <Dialog
      className="w-1/2 modal-h-105 modal-w-750px"
      title={intl.formatMessage({
        id: "app.needs.before-beginning.title",
      })}
      isOpen={show}
      onClose={handleDialogClose}
      canOutsideClickClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="mb-4">
          {intl.formatMessage(
            { id: "app.needs.before-beginning.info" },
            { br: <br /> }
          )}
        </div>
        <div>
          {selectedSurveyDeployment ? (
            <b>{selectedSurveyDeployment?.label}</b>
          ) : (
            ""
          )}
        </div>
        <br />

        <div className={"overflow-y-auto"} style={{ maxHeight: "28rem" }}>
          {bookmarkStatuses.team.length > 0 && (
            <HTMLTable
              className="common-gray-table"
              striped
              interactive
              bordered
            >
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({ id: "app.titles.sclt-members" })}
                  </th>
                  <th>
                    {intl.formatMessage({ id: "app.needs.bookmarks-started" })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookmarkStatuses.team.map((tMs) => (
                  <tr key={tMs.user.id}>
                    <td>{getFullName(tMs.user)}</td>
                    <td>
                      {tMs?.bookmarksStarted && <Icon icon={"tick-circle"} />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          )}
          <br />
          {bookmarkStatuses.district.length > 0 && (
            <HTMLTable
              className="common-gray-table"
              striped
              interactive
              bordered
            >
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage(
                      {
                        id: "app.titles.dclt-members-that-applied-bookmarks",
                      },
                      { em: (chunk) => <em>{chunk}</em> }
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookmarkStatuses.district.map((tMs) => (
                  <tr key={tMs.user.id}>
                    <td>{getFullName(tMs.user)}</td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          )}
        </div>
        <div className={`${Classes.DIALOG_FOOTER} mt-2`}>
          <div className="flex justify-center">
            <Button
              intent="primary"
              text={intl.formatMessage({
                id: "app.needs.start-strengths-and-needs-analysis",
              })}
              title={intl.formatMessage({
                id: "app.needs.start-strengths-and-needs-analysis",
              })}
              onClick={handleDialogClose}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BeforeBeginningDialog;

import { Locale } from "../store/UIState";
import {
  ActivityTagEnum,
  CollectedDemographicDataEnum,
  OpenTimePeriod,
  SimpleAnswerOptions,
  StaffRoleQuestions,
  SurveyDeploymentExcludeAuditAndGlobalAndDistrictsType,
  SurveyDeploymentExcludeAuditType,
  SurveyExcludeCreatorType,
  SurveyQuestionAnswer,
  SurveyResponseRatesKeys,
  UserProfileExcludeBaseModel,
  YesNoAnswerOptions,
} from "./custom";

export * from "./custom";

export type SurveyDeploymentYear = {
  deployment?: string;
  administration?: number;
};
export type SurveyDeploymentDistrictComparison = SurveyDeploymentYear & {
  surveyDeploymentId: number;
  schoolId: number;
  schoolName?: string;
  hideSensitiveData?: boolean;
  domainId?: number | undefined;
  tagId?: number | undefined;
  year?: string;
  month?: string;
  responses?: DemographicWrapper<number>;
};

export type BaseModel = {
  id: number;
  created_at: string;
  updated_at: string;
};

export type NamedModel = BaseModel & {
  name: string;
};

export type District = NamedModel & {
  superintendent?: UserModel;
  new_superintendent?: UserModel;
  is_single_school_district: boolean;
  is_dummy_district?: boolean;
  enabled_data_collection?: boolean;
  enabled_needs_analysis?: boolean;
  enabled_strategic_plan?: boolean;
};

export type School<
  TDistrict extends District | number = number
> = NamedModel & {
  district: TDistrict;
  school_hash: string;
  grades: Grade[];
  principal?: UserModel;
  users?: number[];
};

export type AuthenticatedUserModel = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  is_active?: boolean;
  profile: UserProfile<District | null>;
  team_roles?: Pick<TeamMember, "team_role" | "plan">[];
  is_superuser: string;
  is_staff: boolean;
};


export type ShareFeedbackModel = BaseModel & {
  id: number;
  name:string; 
};

export type UserProfile<TDistrict> = BaseModel & {
  role: UserRole;
  user: number;
  district?: TDistrict;
  schools: number[];
  image_url?: string;
  custom_role_name?: StaffRole | string;
  job_title?: string;
  is_dcc: boolean;
  is_setup_completed: boolean;
};

export type UserModel<
  TDistrict extends District | number | null = District | null,
  TUserProfile extends
  | UserProfile<TDistrict>
  | UserProfileExcludeBaseModel<TDistrict> = UserProfile<TDistrict>
> = BaseModel & {
  profile: TUserProfile;
  email: string;
  first_name: string;
  last_name: string;
  invite_status: InviteStatus;
  is_active: boolean;
  user_agreement: string;
  team_roles?: TeamRolesPlan[];
  bounce_status?: string;
};

export type TeamRolesPlan = {
  plan: number;
  team_role: TeamRole;
};

export type UserEntity = {
  users?: UserModel[];
  invites?: UserInvite[];
};

export type LoginHistoryTeamRole = {
  plan: number;
  team_role: string;
};

export type LoginHistoryList = {
  id: number;
  created_at: string;
  updated_at: string;
  role: string;
  custom_role_name: string;
  user: number;
  district: {
    id: number;
    name: string;
    superintendent: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
    };
    is_single_school_district: boolean;
    enabled_data_collection: boolean;
    enabled_needs_analysis: boolean;
    enabled_strategic_plan: boolean;
  };
  schools?: School[];
  team_roles?: LoginHistoryTeamRole[];
};

export type LoginHistory = {
  list?: LoginHistoryList[];
  totalcount?: number;
};

export type UserInvite<TSchool extends School | number = number> = BaseModel & {
  expires: string;
  code: string;
  district?: number;
  district_name?: string;
  is_single_school_district?: boolean;
  email: string;
  first_name: string;
  last_name: string;
  role?: UserRole;
  custom_role_name?: string;
  invited_user?: number;
  schools?: TSchool[];
  sender: number;
  status: InviteStatus;
  is_dcc: boolean;
  bounce_status?: string;
};

export enum InviteStatus {
  Pending = "pending",
  Sent = "sent",
  Expired = "expired",
  Accepted = "accepted",
  Failed = "failed",
  NA = "N/A",
 
}

export enum UserRole {
  Superadmin = "superadmin",
  NJ_SCI_Leadership = "nj_sci_leadership",
  NJ_SCI_TaProvider = "nj_sci_ta_provider",
  DistrictAdmin = "district_admin",
  DistrictCoordinator = "district_coordinator",
  Superintendent = "superintendent",
  DistrictStaff = "district_staff",
  Principal = "principal",
  VicePrincipal = "vice_principal",
  Staff = "staff",
}

export enum StaffRole {
  ClericalStaff = "Clerical Staff",
  CustodialOrServiceStaff = "Custodial / Service Staff",
  ExternalConsultant = "External Consultant",
  SchoolCounselor = "School Counselor",
  SchoolLevelSupervisor = "School-Level Supervisor",
  InstructionalCoach = "Instructional Coach",
  ParaprofessionalOrSchoolAid = "Paraprofessional or School Aide",
  SchoolPsychologist = "School Psychologist",
  SafetyResourceOfficer = "Safety Resource Officer",
  SchoolNurse = "School Nurse",
  SocialWorker = "Social Worker",
  Teacher = "Teacher",
  Other = "Other",
}

export enum SuperintendentRole {
  AssistantSuperintendent = "Assistant Superintendent",
  // SupervisorOrDirectorOrCoordinator = "Supervisor, Director, or Coordinator",
  DistrictLevelSupervisorOrDirectorOrCoordinator = "District-level Supervisor, Director, or Coordinator",
}

export const userRoles = {
  admins: [
    String(UserRole.Superadmin),
    String(UserRole.NJ_SCI_Leadership),
    String(UserRole.NJ_SCI_TaProvider),
  ],
  districtAdmins: [
    String(UserRole.DistrictAdmin),
    String(UserRole.DistrictCoordinator),
    String(UserRole.Superintendent),
  ],
  districtOrSchoolLeadership: [
    String(UserRole.DistrictAdmin),
    String(UserRole.DistrictCoordinator),
    String(UserRole.Principal),
    String(UserRole.Superintendent),
    String(UserRole.VicePrincipal),
  ],
  schoolLeadership: [
    String(UserRole.Principal),
    String(UserRole.VicePrincipal),
    String(UserRole.Superintendent),
  ],
  schoolStaff: [String(UserRole.Staff), String(UserRole.VicePrincipal)],
  users: [
    String(UserRole.DistrictAdmin),
    String(UserRole.DistrictCoordinator),
    String(UserRole.Principal),
    String(UserRole.Staff),
    String(UserRole.Superintendent),
    String(UserRole.VicePrincipal),
  ],
  all: Object.values(UserRole).map((role: UserRole) => String(role)),
};

export type SurveyDeployment<
  TDistrict extends District | number = District,
  TSurvey extends SurveyExcludeCreatorType | Survey | number = number
> = BaseModel & {
  survey: TSurvey;
  start_date: string; //Date ISO-8601
  end_date: string; //Date ISO-8601
  is_global: boolean;
  is_valid: boolean;
  is_deleted: boolean;
  districts?: TDistrict[];

  responses: DemographicWrapper<number>;

  school_staff_hash?: string;
  parent_hash?: string;
  student_hash?: string;
  elementary_student_hash?: string;

  passwords?: SurveyDeploymentPassword[];

  state?: SurveyDeploymentStateEnum;

  is_some_time_period_closed?: boolean;
  is_confirmed_prioritized_domains?: boolean;

  school_open_periods?: OpenTimePeriod<School>[];

  deployment_name: string;
  njsci_version?: number;
};

export type SurveyTableItem = SurveyDeployment<District, Survey<number>> & {
  school?: School;
  administration?: number;
  deployment?: string;
  year?: string;
  month?: string;
};

export type SurveyDeploymentPassword = BaseModel & {
  school: number;
  demographic: SurveyDemographic;
  password: string;
};

export type SurveyDeploymentStatistics<
  TSurvey extends SurveyExcludeCreatorType | Survey = SurveyExcludeCreatorType,
  TSurveyDeployment extends
  | SurveyDeploymentExcludeAuditAndGlobalAndDistrictsType<TSurvey>
  | SurveyDeploymentExcludeAuditType<TSurvey> = SurveyDeploymentExcludeAuditAndGlobalAndDistrictsType<TSurvey>
> = {
  survey_deployment: TSurveyDeployment;
  responses: GeneralStatistics;
};

export type SummaryStatistics = {
  domain: Domain;
  survey_deployments: SurveyDeploymentStatistics<
    Survey,
    SurveyDeploymentExcludeAuditType<Survey>
  >[];
};

export type SurveyStatistics = {
  domain: Domain;
  not_enough_data: boolean;
  total_respondents: number | null;
  responses: GeneralStatistics<AnswerStatistics> | null;
};

export type DomainStatistics<
  TDemographicStatistics extends
  | AnswerStatistics
  | GroupedAnswerStatistics[] = AnswerStatistics
> = {
  domain: Domain;
  not_enough_data: boolean;
  mean_ratings: GeneralStatistics<DomainDemographicMeanRating>;
  survey_questions: SurveyQuestionStatistics<TDemographicStatistics>[];
  segmentation_code_grouped_by_frontend?: string;
};

export type SurveyQuestionStatistics<TDemographicStatistics> = {
  question_id: number;
  lead_in?: string;
  short_code?: string;
  segmentation_code?: string;
  text: string;
  order?: number;
  deleted_in_njsci_v2?: boolean;
  school_staff: TDemographicStatistics | null;
  parents: TDemographicStatistics | null;
  students: TDemographicStatistics | null;
  elementary_students: TDemographicStatistics | null;
};

export type GroupedSurveyStatistics = {
  domain: Domain;
  responses: GeneralStatistics<GroupedAnswerStatistics[]>;
};

export type GeneralStatistics<
  TStatistics extends
  | StatisticsByDemographic
  | AnswerStatistics
  | GroupedAnswerStatistics[]
  | DomainDemographicMeanRating = StatisticsByDemographic
> = {
  school_staff: TStatistics | null;
  parents: TStatistics | null;
  students: TStatistics | null;
  elementary_students: TStatistics | null;
};

export type DomainDemographicMeanRating = {
  mean_rating: number;
};

export type StatisticsByDemographic = {
  count: number;
  mean_rating: number;
  percent: number;
};

export type GroupedAnswerStatistics = {
  not_enough_data: boolean;
  count?: number | null;
  total_respondents?: number | null;
  label: string;
  value: AnswerStatistics;
  parameters?: Pick<
    Dataset,
    | "direct_instruction"
    | "gender"
    | "grade"
    | "race"
    | "remote_learning"
    | "staff_role"
    | "reduced_lunch"
    | "has_iep"
    | "english_at_home"
  >;
};

export type AnswerStatistics = {
  mean_rating: number;
  not_enough_data: boolean;
  distributions?: AnswerStatisticsDistribution[];
};

type RatingKey = "mean_rating" | "percent";

export type AnswerStatisticsDistribution = {
  [key in RatingKey]?: number;
} & {
  answer_index: number;
  label: string;
  count?: number;
};

export type Survey<
  TCreator extends AuthenticatedUserModel | number = AuthenticatedUserModel
> = NamedModel & {
  creator: TCreator;
  survey_categories_type: SurveyCategoryType;
  available_languages: Locale[];
  use_in_goal_settings?: boolean;
  page_text?: DemographicWrapper<SurveyPrompt>;
  demographic_data?: CollectedDemographicData[];
  supports_bookmarks?: boolean;
  show_reporting_tags_tab?: boolean;
};

export type PageTextKeys = number | "intro" | "post" | "demographic_data";

export type CollectedDemographicData = BaseModel & {
  demographic: SurveyDemographic | null;
  demographic_data: CollectedDemographicDataEnum[];
  survey_deployment?: number | null;
};

export type CompletedSurvey = {
  demographic: SurveyDemographic;
  school: School<number>;
  survey_questions: SurveyQuestion<number, CompletedDomain>[];
  collected_demographic_data: CollectedDemographicData;
  page_text: CompletedSurveyPageText;
  available_languages: Locale[];
  use_in_goal_settings?: boolean;
  session_hash: string;
  survey_questions_completed: SurveyQuestionAnswer[];
};

export type CompletedSurveyPageText = {
  [page in PageTextKeys]: PromptByLanguage;
};

export type CompletedDomain = {
  info: Domain;
  answers: TranslatedAnswerSet;
};

export type SurveyQuestion<
  TSurvey extends Survey | number = number,
  TDomain extends Domain | number | CompletedDomain = number,
  TTag extends number | QuestionTag = number
> = BaseModel & {
  survey: TSurvey;
  local_code?: string; // e.g. en-US
  requested_locale_code?: string;

  domain: TDomain;

  is_not_applicable_answer: boolean;

  demographic: SurveyDemographic;
  text: string;
  page: number;
  order: number;
  lead_in?: string;
  short_code?: string;
  variable_name?: string;
  segmentation_code?: string;

  translations: TranslatedLanguages<QuestionTranslates> | null | undefined;
  question_common_text?: string | null;
  question_tags: TTag[];
  deleted_in_njsci_v2?: boolean;
};

export type QuestionTag = BaseModel & {
  tag: string;
  show_in_insights?: boolean;
};

export enum SurveyDemographic {
  ElementaryStudents = "elementary_students",
  Students = "students",
  SchoolStaff = "school_staff",
  Parents = "parents",
}

export enum SurveyCustomDemographic {
  ElementaryStudents_3_5 = "elementary_students_3_5",
}

export type SurveyReportTaskIdName = {
  task_id: string;
  name: string
}

export type Domain = NamedModel & {
  code: string;
  description: string;
  domain_answer: DomainAnswerSet;
  answer_set_language?: Locale[];
  color_hex?: string;
  mean_score?: string | number;
  focusing_questions?: string | null; // html content
  survey?: number;
  domain_priority?:DomainPriority;
};

export type CommonText<
  TQuestion extends SurveyQuestion | number = SurveyQuestion
> = BaseModel & {
  common_text: string;
  questions: {
    [key in SurveyDemographic]?: TQuestion;
  };
};

export type DomainAnswerSet = BaseModel & {
  is_negative: boolean;
} & {
    [key in SurveyDemographic]?: TranslatedAnswerSet;
  };

export type TranslatedAnswerSet = {
  [langAbbrev: string]: AnswerSet;
};

export type AnswerSet = {
  [answerIndex: number]: string;
};

export enum Grade {
  PK = "PK",
  K = "K",
  G1 = "G1",
  G2 = "G2",
  G3 = "G3",
  G4 = "G4",
  G5 = "G5",
  G6 = "G6",
  G7 = "G7",
  G8 = "G8",
  G9 = "G9",
  G10 = "G10",
  G11 = "G11",
  G12 = "G12",
}

export const StudentGrades: { [key: string]: Grade[] } = {
  [SurveyDemographic.ElementaryStudents]: [
    Grade.PK,
    Grade.K,
    Grade.G1,
    Grade.G2,
    Grade.G3,
    Grade.G4,
    Grade.G5,
  ],
  [SurveyDemographic.Students]: [
    Grade.G6,
    Grade.G7,
    Grade.G8,
    Grade.G9,
    Grade.G10,
    Grade.G11,
    Grade.G12,
  ],
  // custom
  [SurveyCustomDemographic.ElementaryStudents_3_5]: [
    Grade.G3,
    Grade.G4,
    Grade.G5,
  ],
  middle_students: [Grade.G6, Grade.G7, Grade.G8],
  high_students: [Grade.G9, Grade.G10, Grade.G11, Grade.G12],
};

export enum Gender {
  Male = "male",
  Female = "female",
  Transgender = "transgender",
  NonBinary = "non_binary",
  UseDifferentWords = "use_different_words",
}

export enum Race {
  RACE_WHITE = 0,
  RACE_AFRICAN_AMERICAN_OR_BLACK = 1,
  RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE = 2,
  RACE_ASIAN_OR_ASIAN_AMERICAN = 3,
  RACE_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER = 4,
  // RACE_TWO_OR_MORE = 5,
  RACE_HISPANIC_OR_LATINO = 6,
  RACE_OTHER = 7,
  RACE_MIDDLE_EASTERN_NORTH_AFRICAN_ARAB_AMERICAN = 8,
  RACE_GROUP_NOT_LISTED_HERE = 9,
  // RACE_PREFER_NOT_TO_ANSWER = 10,
}

export enum RemoteLearning {
  ATTEND_SCHOOL_BUILDING = 1,
  NOT_MY_SCHOOL_BUILDING = 2,
}

export enum SurveyCategoryType {
  TOPIC_AREA = "topic_area",
  DOMAIN = "domain",
}

export type DatasetParamsKey = keyof Pick<
  Dataset,
  | "direct_instruction"
  | "gender"
  | "grade"
  | "race"
  | "remote_learning"
  | "staff_role"
  | "reduced_lunch"
  | "has_iep"
  | "english_at_home"
  | "demographic"
  | "is_grouped"
>;

export type StackedBarData = {
  x: number;
  y: string;
};

export type StackedBarDataset = {
  original_key: DatasetParamsKey;
  original_value: string;
  label: string;
  data: StackedBarData[];
};

export type StackedBarLabels = {
  label: string;
  original_label_key: DatasetParamsKey;
  original_label_value: string;
  total_respondents: number;
};

export type ChartDataSet = {
  data: (number | null)[];
  datasets?: StackedBarDataset[];
  labels: Array<string | string[]>;
  labelsWithDetail?: StackedBarLabels[];
};

export type NotificationSettings = {
  id: string;
  label: string;
  preferences: {
    in_app: boolean;
    email: boolean;
  };
};

export type Notification = {
  id: number;
  is_active: boolean;
  read: boolean;
  body: string;
  from_user?: AuthenticatedUserModel;
  to_user?: AuthenticatedUserModel;
  email_sent_at?: string;
  scheduled_date?: string;
  notification_type: NotificationType;
};

export enum NotificationType {
  OTHER = "other",
  MESSAGE = "chat_message",
  REMINDER = "reminder",
}

export type DomainQuestionsComparison = {
  domain: Domain;
  mean_score: number;
  responses: DomainResponses[];
};

export type DomainComparisonArray = {
  domain: Domain;
  mean_score: number;
  respondents: DemographicWrapper<DomainQuestionsResponses>;
}[];

export type lastThreeArrays = {
  domain: any;
  meanScore?: any;
}[][];

export type ArrayGroups = {
  lastThreeArrays: {
    domain: any;
    meanScore?: any;
  }[][];
  otherArrays: {
    domain: Domain;
    meanScore?: number;
  }[][];
};


export type DomainComparison = {
  domain: Domain;
  mean_score: number;
  respondents: DemographicWrapper<DomainQuestionsResponses>;
};

export type DomainResponses = {
  common_text_id: number;
  common_text: string;
  respondents: DemographicWrapper<QuestionResponses>;
  count?: number;
  domain?: Domain;
};

export type BookmarkDetail = {
  domainIndex: number | undefined;
  questionId: number | undefined;
  selectedLabelKey: string;
  selectedLabelValue: string;
};

export type DomainQuestionsResponses = {
  mean_score?: number;
  count?: number;
  not_enough_data: boolean;
  distribution: AnswerStatisticsDistribution[];
  total_respondents?: number;
  triage_status?: DomainPriorityTriageStatus | null;
};

export type QuestionResponses = {
  question_id: number;
  question_text: string;
  mean_score?: number;
  count?: number;
  not_enough_data: boolean;
  distribution: AnswerStatisticsDistribution[];
  distribution_group_by: AnswerStatisticsGroupedDistribution[];
  is_deleted?: boolean;
  demographic?: string;
};

export type DemographicOnly = {
  demographic?: string;
};

export type AnswerStatisticsGroupedDistribution = {
  grouped_by: string;
  value: string;
  mean_score: number;
  count: number;
  distribution: AnswerStatisticsDistribution[];
};

export type Insight = {
  title: string;
  code?: string;
  demographics: {
    [key in SurveyDemographic]?: InsightDemographic[];
  };
};

export type InsightDemographic = {
  title: CollectedDemographicDataEnum;
  results: InsightDemographicResult[];
  note?: string;
};

export type InsightDemographicResult = {
  title: string;
  domains?: [number, number][];
  domain_total?: number;
  items?: [number, number][];
  item_total?: number;
};

export type SurveyPrompt = {
  [page in PageTextKeys]: PromptByLanguage;
};

export type PromptByLanguage = {
  [languageAbbreviation: string]: Prompt;
};

export type Prompt = {
  instruction?: string;
  statement?: string;
};

export type QuestionTranslates = {
  lead_in?: string;
  text?: string;
};

export type TranslatedLanguages<T> = {
  [localeAbbreviation: string]: T;
};

export type SurveyPagesByDemographic = {
  [demographic in SurveyDemographic]: number[];
};

export type DemographicWrapper<T> = {
  [demographic in SurveyDemographic]?: T;
};

export type SurveyDeploymentResponses = {
  responses: DemographicWrapper<number>;
};

export type ResponseRates = {
  response_rates: DemographicWrapper<ResponseRatesByDemographic[]>;
};

export type ResponseItemType = {
  label: string;
  count: number | JSX.Element;
};

export type ResponseRatesByDemographic = {
  title: SurveyResponseRatesKeys;
  note?: string;
  results: ResponseItemType[];
};

export type SurveyDeploymentOpenTimePeriod<
  TSchool extends School | number = School
> = BaseModel & {
  start_date?: string;
  end_date?: string;
  created_at?: any;
  demographic?: SurveyDemographic;
  survey_deployment: number;
  school: TSchool;
};

export type Plan<
  TDistrict extends District | number = District,
  TSchool extends School | number = School
> = BaseModel & {
  is_active: boolean;
  district: TDistrict;
  school: TSchool;
  school_year_start_month: number;
  school_year_end_month: number;
  target_years: number;
  implementation_start_target_date: string;
  strategies: PlanStrategy[];
  needs: PrioritizedNeed[];
  meetings: Meeting[];
  scheduled_events: ScheduledEvent[];
};

export type PrioritizedNeed = BaseModel & {
  // plan: Plan;
  dataset: Dataset;
};

export type PriorityAreaCheck = {
  priority_area_count: number;
};

export type Dataset = NamedModel & {
  type: DatasetType; // Possibly more types coming
  measurement_type?: MeasurementType;
  // Numeric Timeseries just means they type in the value and date to create a series of NumericDataPoints
  data_points?: DataPoint[]; // if numeric ic_timeseries
  possible_values?: PossibleValue[];
  survey?: number; // if survey
  domain?: Domain;
  question?: SurveyQuestion;
  grade?: string;
  race?: string;
  gender?: string;
  remote_learning?: YesNoAnswerOptions;
  direct_instruction?: boolean;
  demographic?: SurveyDemographic;
  language?: string;
  is_grouped?: boolean;
  staff_role?: StaffRoleQuestions;
  reduced_lunch?: YesNoAnswerOptions;
  has_iep?: YesNoAnswerOptions;
  english_at_home?: SimpleAnswerOptions;
  barPercentage?: Number;
  datalabels?: any;
};

export type PossibleValue = BaseModel & {
  order: number;
  name: string;
};

export enum DatasetType {
  MeasurementTimeseries = "measurement_timeseries",
  Survey = "survey",
}

export enum MeasurementType {
  Number = "number",
  Percentage = "percentage",
  Ratio = "ratio",
  Labels = "labels",
}

export type DataPoint = BaseModel & {
  date: string;
  value: string | number; // String representation allows us to store ratios and other data types, e.g. "0.3323" or "12" or "1:3"
  survey_deployment_id?: number;
  numeric_type?: MeasurementType;
};

export type Meeting<
  TDate extends string | Date = string,
  IsShorten extends boolean = true
> = BaseModel & {
  title: string;
  short_title?: string;
  all_day_event?: boolean;
  date: TDate;
  end_date: TDate;
  general_notes?: string;
  pre_work?: string;
  agenda?: string;
  attached_resources: IsShorten extends true ? number[] : AttachedResource[];
  user_notes: IsShorten extends true ? number[] : UserNotes[];
  plan?: number;
  meeting_code?: string;
  activities?: IsShorten extends true ? number[] : Activity[];
  timing_guidance?: string;
  meeting_type?: MeetingType;
  meeting_type_other?: string;
  is_hidden?: boolean;
  system_event?: boolean;
};

export enum MeetingType {
  TeamMeeting = "team_meeting",
  DistrictWideLeadershipMeeting = "district_wide_leadership_meeting",
  NJSCILearningNetworkEvent = "nj_sci_learning_network_event",
  TASK = "task",
  Other = "other",
}

export type ScheduledEvent = BaseModel & {
  date: string;
  description: string;
  roles_required: TeamRole[]; // User roles list
};

export type AttachedResource = BaseModel & {
  type: AttachedResourceType;
  attached_to_type: AttachedToType;
  attachment?: File;
  description: string;
  title: string;
  url?: string;
  attachment_url?: string;
  topic_tag?: TopicTag;
  topic_tag_other?: string;
  user_tag?: UserTag;
  ext?: string;
};

export enum UserTag {
  RUTGERS_SCTP_STAFF = "rutgers_sctp_staff",
  SCHOOL_TEAM_MEMBER = "school_team_member",
  DISTRICT_TEAM_MEMBER = "district_team_member",
}

export enum TopicTag {
  TEAMING = "teaming",
  DATA_COLLECTION = "data_collection",
  DATA_ANALYSIS = "data_analysis",
  STRATEGIC_PLANNING = "strategic_planning",
  COMMUNICATION_WITH_STAKEHOLDERS = "communication_with_stakeholders",
  OTHER = "other",
}

export enum AttachedToType {
  STRATEGY = "strategy",
  USER_NOTES = "user_notes",
  MEETING = "meeting",
  SCHOOL_RESOURCES_WORKSPACE = "school_resources_workspace",
  DISTRICT_RESOURCES_WORKSPACE = "district_resources_workspace",
  ACTIVITY = "activity",
  PDF_STRATEGY = "pdf_strategy",
}

export enum AttachedResourceType {
  Link = "link",
  Upload = "upload",
}

export type UserNotes<
  TCreator extends
  | Pick<AuthenticatedUserModel, "id" | "first_name" | "last_name" | "is_active">
  | number = Pick<AuthenticatedUserModel, "id" | "first_name" | "last_name" | "is_active">
> = BaseModel & {
  meeting?: number;
  meetings?:Meeting[],
  plan: number;
  user: TCreator;
  text?: string;
  resources: AttachedResource[];
  is_json: boolean;
  text_activity?: string;
  note_type?: UserNotesType;
  note_title?:string;
  is_draft?: boolean;
  activity?: Pick<Activity, "id" | "name">;
  viewable_by?: UserNotesViewableBy;
  is_calender_note:boolean;
  is_edit_mode?:boolean;
};

export enum UserNotesViewableBy {
  AllMembers = "all_members",
  JustMe = "just_me",
}

export enum UserNotesType {
  SCLT_TEAM_HOME = "sclt_team_home",
  SCLT_SCI_CALENDAR = "sclt_sci_calendar",
  SCLT_RESOURCES = "sclt_resources",
  DC_REPORTING_ADMINISTRATION_GUIDELINES = "dc_reporting_administration_guidelines",
  DC_REPORTING_SURVEY_DEPLOYMENTS = "dc_reporting_survey_deployments",
  DC_REPORTING_DISTRICT_COMPARISON = "dc_reporting_district_comparison",
  DC_REPORTING_REPORTS = "dc_reporting_reports", // when viewing an actual SD report, not really a tab
  DC_REPORTING_SUPPLEMENTAL_DATA = "dc_reporting_supplemental_data",
  DC_REPORTING_RESOURCES = "dc_reporting_resources",
  SNA_EXPLORE_STRENGTHS_NEEDS = "sna_explore_strengths_needs", // New tab, doesn't exist yet
  SNA_DIGGING_DEEPER = "sna_digging_deeper", // New tab, doesn't exist yet
  SNA_PRIORITIZE_NEEDS = "sna_prioritize_needs", // New tab, doesn't exist yet
  SNA_RESOURCES = "sna_resources",
  STRATEGIC_PLAN_WORKSPACE = "strategic_plan_workspace",
  SCHOOL_RESOURCES_WORKSPACE = "school_resources_workspace",
  SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE = "school_learning_modules_events_workspace",

  DCLT_TEAM_HOME = "dclt_team_home",
  DCLT_SCI_CALENDAR = "dclt_sci_calendar",
  DCLT_RESOURCES = "dclt_resources",
  SCHOOL_USER_MANAGEMENT = "school_user_management",
  DC_REPORTS_SURVEY_DEPLOYMENTS = "dc_reports_survey_deployments",
  DC_REPORTS_DISTRICT_COMPARISON = "dc_reports_district_comparison",
  DC_REPORTS_RESOURCES = "dc_reports_resources",
  DISTRICT_RESOURCES_WORKSPACE = "district_resources_workspace",
  DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE = "district_learning_modules_events_workspace",
}

export enum UserNotesTypePublish {
  SCLT_TEAM_HOME = "sclt_team_home",
  SCLT_SCI_CALENDAR = "sclt_sci_calendar",
  SCLT_RESOURCES = "sclt_resources",
  DC_REPORTING_ADMINISTRATION_GUIDELINES = "dc_reporting_administration_guidelines",
  DC_REPORTING_SURVEY_DEPLOYMENTS = "dc_reporting_survey_deployments",
  DC_REPORTING_DISTRICT_COMPARISON = "dc_reporting_district_comparison",
  DC_REPORTING_REPORTS = "dc_reporting_reports", // when viewing an actual SD report, not really a tab
  DC_REPORTING_SUPPLEMENTAL_DATA = "dc_reporting_supplemental_data",
  DC_REPORTING_RESOURCES = "dc_reporting_resources",
  SNA_EXPLORE_STRENGTHS_NEEDS = "sna_explore_strengths_needs", // New tab, doesn't exist yet
  SNA_DIGGING_DEEPER = "sna_digging_deeper", // New tab, doesn't exist yet
  SNA_PRIORITIZE_NEEDS = "sna_prioritize_needs", // New tab, doesn't exist yet
  SNA_RESOURCES = "sna_resources",
  STRATEGIC_PLAN_WORKSPACE = "strategic_plan_workspace",
  SCHOOL_RESOURCES_WORKSPACE = "school_resources_workspace",
  SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE = "school_learning_modules_events_workspace",

  DCLT_TEAM_HOME = "dclt_team_home",
  DCLT_SCI_CALENDAR = "dclt_sci_calendar",
  DCLT_RESOURCES = "dclt_resources",
  SCHOOL_USER_MANAGEMENT = "school_user_management",
  DC_REPORTS_SURVEY_DEPLOYMENTS = "dc_reports_survey_deployments",
  DC_REPORTS_DISTRICT_COMPARISON = "dc_reports_district_comparison",
  DC_REPORTS_RESOURCES = "dc_reports_resources",
  DISTRICT_RESOURCES_WORKSPACE = "district_resources_workspace",
  DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE = "district_learning_modules_events_workspace",
}
export const userNotesTypeValues = {
  schoolLevel: [
    String(UserNotesType.SCLT_TEAM_HOME),
    String(UserNotesType.SCLT_SCI_CALENDAR),
    String(UserNotesType.SCLT_RESOURCES),
    String(UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES),
    String(UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS),
    String(UserNotesType.DC_REPORTING_REPORTS),
    String(UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA),
    String(UserNotesType.DC_REPORTING_RESOURCES),
    String(UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS),
    String(UserNotesType.SNA_DIGGING_DEEPER),
    String(UserNotesType.SNA_PRIORITIZE_NEEDS),
    String(UserNotesType.SNA_RESOURCES),
    String(UserNotesType.STRATEGIC_PLAN_WORKSPACE),
    String(UserNotesType.SCHOOL_RESOURCES_WORKSPACE),
    String(UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE),
  ],
  districtLevel: [
    String(UserNotesType.DCLT_TEAM_HOME),
    String(UserNotesType.DCLT_SCI_CALENDAR),
    String(UserNotesType.DCLT_RESOURCES),
    String(UserNotesType.DC_REPORTS_SURVEY_DEPLOYMENTS),
    String(UserNotesType.DC_REPORTS_DISTRICT_COMPARISON),
    String(UserNotesType.DC_REPORTS_RESOURCES),
    String(UserNotesType.DISTRICT_RESOURCES_WORKSPACE),
    String(UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE),
  ],
};



export const userNotesTypeValuePublishedNotes = {
  schoolLevel: [
    String(UserNotesTypePublish.SCLT_TEAM_HOME),
    String(UserNotesTypePublish.DC_REPORTING_REPORTS),
    String(UserNotesTypePublish.SNA_EXPLORE_STRENGTHS_NEEDS),
    String(UserNotesTypePublish.STRATEGIC_PLAN_WORKSPACE),

  ],
  districtLevel: [
    String(UserNotesTypePublish.DCLT_TEAM_HOME),
    String(UserNotesTypePublish.DC_REPORTS_DISTRICT_COMPARISON),
  ],
};



export type GoalIndicatorTarget = BaseModel & {
  need: PrioritizedNeed;
  direction: GoalIndicatorTargetDirection;
  domain?: Domain,
  target_date: string;
  target_value: string | number; // aligned with the Dataset type;
  domain_priority: any;
  domain_priority_id?: number;
  dataset: number | Dataset;
  priority_area?: number;
  demographic?: string;
  is_confirmed?: boolean;
  type?: string
  item_priority?: any;

};

export type Goal = BaseModel & {
  is_active: boolean;
  statement: string;
  is_archived: boolean;
  is_completed?: boolean;
  is_confirmed: boolean;
  targets: GoalIndicatorTarget[];
  plan_strategies: PlanStrategy[];
  user?: UserModel;
  priority_areas?: priorityAreas | priorityAreas[];
  desired_outcome?: string;
  selected_domains?: Domain[];
  survey_deployment?: SurveyDeployment;
  domain_targets?: GoalIndicatorTarget[];
  item_targets?: GoalIndicatorTarget[];
  plans?: Plan[];
  school_name?: Plan[];
  district_name?: Plan[];
  plan_id?:number|undefined;
};

export type GoalAllActivityLogs ={
  created_at: string;
  updated_at: string;
  is_active: boolean;
  statement: string;
  is_archived: boolean;
  is_completed?: boolean;
  is_confirmed: boolean;
  targets: GoalIndicatorTarget[];
  plan_strategies: PlanStrategy[];
  user?: UserModel;
  priority_areas?: priorityAreas | priorityAreas[];
  desired_outcome?: string;
  selected_domains?: Domain[];
  survey_deployment?: SurveyDeployment;
  domain_targets?: GoalIndicatorTarget[];
  item_targets?: GoalIndicatorTarget[];
  plans?: BaseModel & {
    is_active: boolean;
    district: District;
    school: School;
    school_year_start_month: number;
    school_year_end_month: number;
    target_years: number;
    implementation_start_target_date: string;
    strategies: PlanStrategy[];
    needs: PrioritizedNeed[];
    meetings: Meeting[];
    scheduled_events: ScheduledEvent[][];
  school_name?: Plan[];
  district_name?: Plan[];
  plan_id?:number|undefined;
}
}

export enum GoalIndicatorTargetDirection {
  Increase = "increase",
  //Decrease = "decrease",
  Maintain = "maintain",
}

export type GoalActivityLog = BaseModel & {
  id: number;
  created_at: string;
  updated_at: string;
  user?: UserModel;
  goal?: Goal;
  goal_indicator?: GoalIndicatorTarget;
  updated: boolean;
  is_target_date_changed: boolean;
  is_target_value_changed: boolean;
  is_item_domain_deleted: boolean;
};

export type GoalActivityLogAll = {
  list?: Goal[];
  totalcount?: number;
}


export type Strategy<
  TCreator extends AuthenticatedUserModel | number = AuthenticatedUserModel
  > = NamedModel & {
    // name: string; // E.g. "Social Emotional Learning (SEL)"
    domains: Domain[]; // Many to many
    description: string; // Rich text, markdown, can be very long
    supporting_evidence: string; // Rich text, markdown, can be very long
    look_for_items: LookForItem[];
    is_global: boolean;
    resources: AttachedResource[];
    creator?: TCreator | null;
    school?: number; //if not defined strategy is global
    pdf_resource?: AttachedResource;
    district?: number;
};

export type PlanStrategy = BaseModel & {
  strategy: Strategy;
  look_for_statuses: LookForItemStatus[]; // Status of each LookForItem for this strategy in the plan
  status?: PlanStrategyStatus;
  target_groups?: PlanStrategyTargetGroups;
  grades?: string[];
  launch_date?: string;
  locations?: string;
  timeframe?: string;
  general_approach?: string;
  implementations: LoggedStrategyImplementation[];
  leaders?: UserModel[];
  respondent_group?: string[];
  is_pause_implimentation: boolean;
};

export type LoggedStrategyImplementation = BaseModel & {
  plan_strategy: number;
  score: number | null;
  date: string;
  statuses: LoggedStrategyLookForStatus[];
};

export type LoggedStrategyLookForStatus<
  TLookForItem extends
  | LookForItem
  | Omit<LookForItem, "id" | "created_at" | "updated_at">
  | number = number
> = BaseModel & {
  item: TLookForItem;
  status: LookForItemStatusEnum;
};

export enum PlanStrategyStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum PlanStrategyTargetGroups {
  AllStudents = "all_students",
  SmallGroups = "small_groups",
}

export enum PlanStrategyRespondentGroups {
  Students = "students",
  Staff = "school_staff",
  Parents = "parents"
}

export enum SortByEnum {
  NEWFIRST = "newest_first",
  OLDESTFIRST = "oldest_first",
  CUSTOM = "custom",
}

export type LookForItemStatus<
  TLookForItem extends
  | LookForItem
  | Omit<LookForItem, "id" | "created_at" | "updated_at">
  | number = number
> = BaseModel & {
  item: TLookForItem;
  target_start_date?: string;
  target_end_date?: string;
  details?: string;
  recurring_status: RecurringStatusEnum;
    status?: string;
    plan_id?: number;
};

export enum RecurringStatusEnum {
  NotRecurring = "not_recurring",
  Yearly = "yearly",
  Monthly = "monthly",
  SixMonths = "six_months",
}

export enum LookForItemStatusEnum {
  NotPresent = "not_present",
  InProgress = "in_progress",
  Present = "present",
}

export type LookForItem = BaseModel & {
  text: string;
  original: boolean; //by default true
  plan_id?: number;
  school_item: boolean;
};

export enum TeamRole {
  TeamChampion = "team_champion",
  TeamChair = "team_chair",
  DataCoordinator = "data_coordinator",
  TeamMember = "team_member",
  //SchoolStakeholder = "school_stakeholder",
}

export type TeamMember = {
  plan: number;
  user: UserModel;
  team_role: TeamRole;
  invited_status: InviteStatus | null;
  invite_id?: number;
  invite_expires_date?: string | number | Date;
};

export type DatasetMark = BaseModel & {
  creator?: number;
  dataset: Dataset;
  school: number;
  bookmark_type: BookmarkType;
};

export enum BookmarkType {
  Positive = "positive",
  Negative = "negative",
}

export enum PlanStep {
  EstablishTeam = "establish_team",
  CollectData = "collect_data",
  AnalyzeData = "analyze_data",
  SetGoals = "set_goals",
  AlignStrategies = "align_strategies",
  MaintainTeam = "maintain_team",
  MonitoringCollectData = "monitoring_collect_data",
  TrackGoals = "track_goals",
  TrackStrategies = "track_strategies",
}

export type PlanStatus = {
  [key in PlanStep]: boolean;
};

export enum SurveyDeploymentStateEnum {
  SCHEDULED = "scheduled",
  IN_PROGRESS = "in_progress",
  QUEUED = "queued",
  GENERATING_DATASETS = "generating_datasets",
  GENERATION_ERROR = "generation_error",
  READY = "ready",
}

export type Activity = NamedModel & {
  type: ActivityScopeEnum;
  activity_type: ActivityTypeEnum;
  tag: ActivityTagEnum;
  html_content?: string;
  docx_resource?: AttachedResource;
  pdf_resource?: AttachedResource;
  ppt_resource?: AttachedResource;
  external_url?: string;
  embed_code?: string;
  notes_placeholder_html?: string;
  order_index?: number;
};

export enum ActivityTypeEnum {
  SCI_WRITING = "sci_writing",
  EXTERNAL_RESOURCE = "external_resource",
  KALTURA_VIDEO = "kaltura_video",
}

export enum ActivityScopeEnum {
  SCHOOL = "school",
  DISTRICT = "district",
  ALL = "all",
}

export type MeetingTemplate = Omit<
  Meeting<string, false>,
  "date" | "end_date" | "plan" | "user_notes" | "attached_resources"
> & {
  activities: Activity[];
  resources: AttachedResource[];
  order_index?: number;
  can_be_added?: boolean;
  type: ActivityScopeEnum;
  is_hidden?: boolean;
};

export type UserTermsAgreement = {
  id: number;
  date: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  district_name?: string;
  school_role: UserRole;
  custom_role_name?: string;
};
export type UserLoginHistory = {
  id: number;
  date: string;
  district: string;
  school?: string;
  district_school_role: string;
  school_team_role?: string;
};

export type ActionAudit = {
  id: number;
  date: string;
  district: string;
  district_school_role: string;
  action_type: string;
  target: string;
};

export type CountAudit = {
  count_district: number;
  count_school: number;
  count_users: number;
  count_active_users: number;
};

export type UserActionAuditResponse = {
  list: UserActionAudit[];
  totalcount: number;
};
export type UseAllActionAuditResponse = {
  list: UserActionAudit[];
  totalcount?: number;
};
export type UserActionAudit = {
  id: number;
  created_at: string;
  updated_at: string;
  district: District;
  role: string;
  targets: string;
  school: string;
  user: number;
  action_type: string;
  school_in_which_action_done:string;

};

export type PageSizeNumber = {
  page_no: number;
  page_size: number;
};

export type SchoolNameTargets = {
  deploymentYear?: string;
  school?: string;
};

export type SchoolNameDeplyments = {
  event: string;
  targets: SchoolNameTargets[] | string;
};

export type SchoolNameDeplymentTarget = {
  event: string;
  targets: SchoolNameTargets[];
};

export enum SciWritingFilterEnum {
  SCHOOL_CLIMATE_LEADERSHIP_TEAM = "school_climate_leadership_team",
  DATA_COLLECTION_REPORTS = "data_collection_reports",
  STRENGTHS_NEEDS_ANALYSIS = "strengths_needs_analysis",
  STRATEGIC_PLAN_WORKSPACE = "strategic_plan_workspace",
  RESOURCES_WORKSPACE = "resources_workspace",
  LEARNING_MODULES_EVENTS = "learning_modules_events",

  DISTRICT_CLIMATE_LEADERSHIP_TEAM = "district_climate_leadership_team",
  // SCHOOL_USER_MANAGEMENT = "school_user_management",
}

export const sciWritingFilterValues = {
  districtLevel: [
    SciWritingFilterEnum.DISTRICT_CLIMATE_LEADERSHIP_TEAM,
    // SciWritingFilterEnum.SCHOOL_USER_MANAGEMENT,
    SciWritingFilterEnum.DATA_COLLECTION_REPORTS,
    SciWritingFilterEnum.RESOURCES_WORKSPACE,
    SciWritingFilterEnum.LEARNING_MODULES_EVENTS,
  ],
  schoolLevel: [
    SciWritingFilterEnum.SCHOOL_CLIMATE_LEADERSHIP_TEAM,
    SciWritingFilterEnum.DATA_COLLECTION_REPORTS,
    SciWritingFilterEnum.STRENGTHS_NEEDS_ANALYSIS,
    SciWritingFilterEnum.STRATEGIC_PLAN_WORKSPACE,
    SciWritingFilterEnum.RESOURCES_WORKSPACE,
    SciWritingFilterEnum.LEARNING_MODULES_EVENTS,
  ],
};

export const sciWritingFilterUserNotesMapper = {
  districtLevel: {
    [SciWritingFilterEnum.DISTRICT_CLIMATE_LEADERSHIP_TEAM]: [
      UserNotesType.DCLT_TEAM_HOME,
      UserNotesType.DCLT_SCI_CALENDAR,
      UserNotesType.DCLT_RESOURCES,
    ],
    // [SciWritingFilterEnum.SCHOOL_USER_MANAGEMENT]: [
    //   UserNotesType.SCHOOL_USER_MANAGEMENT,
    // ],
    [SciWritingFilterEnum.DATA_COLLECTION_REPORTS]: [
      UserNotesType.DC_REPORTS_SURVEY_DEPLOYMENTS,
      UserNotesType.DC_REPORTS_DISTRICT_COMPARISON,
      UserNotesType.DC_REPORTS_RESOURCES,
    ],
    [SciWritingFilterEnum.RESOURCES_WORKSPACE]: [
      UserNotesType.DISTRICT_RESOURCES_WORKSPACE,
    ],
    [SciWritingFilterEnum.LEARNING_MODULES_EVENTS]: [
      UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE,
    ],
  },
  schoolLevel: {
    [SciWritingFilterEnum.SCHOOL_CLIMATE_LEADERSHIP_TEAM]: [
      UserNotesType.SCLT_TEAM_HOME,
      UserNotesType.SCLT_SCI_CALENDAR,
      UserNotesType.SCLT_RESOURCES,
    ],
    [SciWritingFilterEnum.DATA_COLLECTION_REPORTS]: [
      UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES,
      UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      UserNotesType.DC_REPORTING_REPORTS,
      UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA,
      UserNotesType.DC_REPORTING_RESOURCES,
    ],
    [SciWritingFilterEnum.STRENGTHS_NEEDS_ANALYSIS]: [
      UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS,
      UserNotesType.SNA_DIGGING_DEEPER,
      UserNotesType.SNA_PRIORITIZE_NEEDS,
      UserNotesType.SNA_RESOURCES,
    ],
    [SciWritingFilterEnum.STRATEGIC_PLAN_WORKSPACE]: [
      UserNotesType.STRATEGIC_PLAN_WORKSPACE,
    ],
    [SciWritingFilterEnum.RESOURCES_WORKSPACE]: [
      UserNotesType.SCHOOL_RESOURCES_WORKSPACE,
    ],
    [SciWritingFilterEnum.LEARNING_MODULES_EVENTS]: [
      UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE,
    ],
  },
};

export type PriorityDataPoint = {
  id?: number;
  type?: string;
  numeric_type?: string;
  date?: string;
  value?: number;
  survey_deployment_id?: number;
};

export type DomainPriority = BaseModel & {
  domain: Omit<Domain, "domain_answer" | "answer_set_language">;
  data_points: PriorityDataPoint[]; // data_points
  survey_deployment: number; // FK
  demographic: SurveyDemographic|string;
  triage_status: DomainPriorityTriageStatus | null;
  decision: DomainPriorityDecision | null;
  plan?: number | null;
};

export enum DomainPriorityDecision {
  Dismissed = "dismissed",
  Prioritized = "prioritized",
}

export type Priority = BaseModel & {
  confirmed: boolean;
  survey_deployment: number; // FK
  domain_priorities: DomainPriority[]; // FK
  text?: string;
  survey?: Survey;
  deployment_name?: string;
  plan?: number;
  old_priority?: boolean;
  deployment_Year?: string;
  administration_no?: number;
  is_latest?: boolean;
  used_in_another_goal?: boolean;
};
export type priorityAreas = {
  name: string;
  label: string;
  surveyCards: { [key: string]: Priority[] };
};

export enum DomainPriorityTriageStatus {
  Archived = "archived",
  PotentialPriority = "potential_priority",
}

export enum GraphTypeEnum {
  INDICATOR_GRAPH = "indicator_graph",
  RESPONDENT_GROUP_GRAPH = "respondent_group_graph"
}
import {
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import Select, { ValueType, ActionMeta, OptionsType } from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { downloadSampleLetters } from "../../../store/surveys-deployment/actions";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { TeamRole } from "../../../types";
import { Locale, LocaleEnglishValues } from "../../../store/UIState";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// swiper slide
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import FormControl from '@mui/material/FormControl';

type OwnProps = {
  surveyDeploymentId?: number;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const SampleLettersDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, setOpen, surveyDeploymentId } = props;
  const activePlan = useSelector((s) => s.plans?.activePlan);
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const [schoolPrincipalName, setSchoolPrincipalName] = useState("");
  const [dataCoordinatorName, setDataCoordinatorName] = useState("");
  const [dataCoordinatorEmail, setDataCoordinatorEmail] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [state, setState] = React.useState(false);
  const formattedString = intl.formatMessage({ id: "app.surveys-deployment-table.columns.actions.download-letters" });
  const modifiedString = formattedString.toUpperCase().replace(/s/, '');


  const languageOption: { label: string; value: string }[] = useMemo(() => {
    const languageOptions: { label: string; value: string }[] = [];
    Object.keys(Locale).forEach(function (key, index) {
      if (key !== LocaleEnglishValues[Locale.English])
        languageOptions.push({
          label: key.replace(/([A-Z])/g, " $1").trim(),
          value: key,
        });
    });
    return languageOptions;
  }, [LocaleEnglishValues]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  // useEffect(() => {
  //   if (activePlan?.id) {
  //     dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
  //   }
  // }, [activePlan?.id]);

  const handleDialogOpen = useCallback(() => {
    if (activePlan) {
      const { principal } = activePlan.school;
      setSchoolPrincipalName(
        principal?.first_name + " " + principal?.last_name
      );
      const firstDataCoordinator = members?.find(
        (m) => m.team_role === TeamRole.DataCoordinator
      );
      if (firstDataCoordinator) {
        setDataCoordinatorEmail(firstDataCoordinator.user?.email);
        setDataCoordinatorName(
          firstDataCoordinator.user?.first_name +
            " " +
            firstDataCoordinator.user?.last_name
        );
      } else {
        setDataCoordinatorName("");
        setDataCoordinatorEmail("");
      }
    }
  }, [activePlan, members]);

  const handleSchoolPrincipalNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSchoolPrincipalName(e.target.value);
  };

  const handleDataCoordinatorNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDataCoordinatorName(e.target.value);
  };

  const handleDataCoordinatorEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDataCoordinatorEmail(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(dataCoordinatorName && dataCoordinatorEmail && schoolPrincipalName){
    if (activePlan?.id) {
      dispatch(
        downloadSampleLetters.request({
          surveyDeploymentId: surveyDeploymentId!,
          planId: activePlan.id,
          body: {
            data_coordinator_email: dataCoordinatorEmail,
            data_coordinator_name: dataCoordinatorName,
            team_champion_name: schoolPrincipalName,
            languages: selectedLanguages, //selectedLanguages?.map((e) => e?.value)
          },
        })
      );
    }
  }
  };

  const languageSelection = function (e: any) {
    const lang: string[] = e?.map(
      (e: { label: string; value: string }) => e?.value
    );
    setSelectedLanguages(lang);
  };

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.downloadSampleLetters
  );

  const error = useSelector(
    (s) => s.surveysDeployment.errors.downloadSampleLetters
  );

  useLoading({ loading, error });

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setState(open);
  };

  return (
    <div className="teampage_container data-collection-report">
      <SwipeableDrawer
        anchor={"right"}
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="download-popup">
          <DialogTitle>
          {intl.formatMessage({ id: "app.surveys-deployment-table.columns.actions.download-letters" })}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="content-area">
            <p style={{ fontSize: '16px'}}>
              {intl.formatMessage({ id: "app.survey.deployment.download.sample.letter" })}
            </p>
            <form id="sample-letters-form" onSubmit={handleFormSubmit}>
              <FormControl fullWidth >
                <TextField
                  label="Data Coordinator Name"
                  variant="outlined"
                  className="input-form"
                  value={dataCoordinatorName}
                  onChange={(e) => setDataCoordinatorName(e.target.value)}
                  fullWidth
                  error={dataCoordinatorName?false:true} 
                  helperText={dataCoordinatorName.length ?'': 'This field is required' }
                />
              </FormControl>
              <FormControl fullWidth >
                <TextField
                  label="Data Coordinator Email"
                  type="email"
                  variant="outlined"
                  className="input-form"
                  value={dataCoordinatorEmail}
                  onChange={(e) => setDataCoordinatorEmail(e.target.value)}
                  fullWidth
                  error={dataCoordinatorEmail?false:true} 
                  helperText={dataCoordinatorEmail.length ? '':'This field is required' }
                />
              </FormControl>
              <FormControl fullWidth >
                <TextField
                  label="School Principal (Team Champion Name)"
                  variant="outlined"
                  className="input-form"
                  value={schoolPrincipalName}
                  onChange={(e) => setSchoolPrincipalName(e.target.value)}
                  fullWidth
                  error={schoolPrincipalName?false:true} 
                  helperText={schoolPrincipalName.length ? '':'This field is required' }
                />
              </FormControl>
              <FormControl fullWidth >
                <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Translations needed in addition to English"
                              className="input-form"
                              
                                        isMulti
                                        isClearable={false}
                                        onChange={languageSelection}
                                        options={languageOption}
                                        placeholder="Translations needed in addition to English"
                            >
                               <option>
                               {intl.formatMessage({ id: "app.sample-letters-dialog.translations" })}
                               </option> 
                            </Select>


                            <div style={{fontFamily: 'italic', fontSize: '14px', marginTop: '8px' }}>
                            {intl.formatMessage({ id: "app.survey.deployment.download.parents.caregiver" })}
                            
                </div>
              </FormControl>
              <Divider className="h-0.9"></Divider>
              <p style={{ fontSize: '14px', marginTop: '16px', color: 'black' }}>
                <b>Note:</b> {intl.formatMessage({ id: "app.survey.deployement.notes" })}
              </p>
            </form>
          </DialogContent>
          <DialogActions className="footer-btn">
            <Button
              autoFocus
              className="btn btn-primary"
              form="sample-letters-form"
              type="submit"
              color="primary"
              disabled={loading}
              style={{
                backgroundImage: 'none',
              }}
            >
          {modifiedString}
            </Button>
          </DialogActions>
        </div>
      </SwipeableDrawer>
    </div>
);
};

export default SampleLettersDialog;

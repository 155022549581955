import { Button, Callout, Classes, Dialog, Icon } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { getCurrentUserDistrictId } from "../../../store/auth/selectors";
import { getUsers, hideUserEditingDialog } from "../../../store/users/actions";
import {
  deleteTeamMemberFromPlan,
  hideUpdateTeamMemberDialog,
} from "../../../store/team-members/actions";
import UserEditingForm from "./UserEditingForm";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { getSchoolsByDistrict } from "../../../store/schools/actions";
import "./userEditingForm.scss";
type OwnProps = {
  isDistrictSelectorAvailable?: boolean;
};

type Props = OwnProps;

const UserEditingDialog: React.FC<Props> = (props: Props) => {
  const { isDistrictSelectorAvailable } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const { districtId } = useParams<{ districtId: string }>();

  const currentUserDistrictId = useSelector(getCurrentUserDistrictId);

  const [warning, setWarning] = useState<string | undefined>();
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [domainDeleteModalOpen, setDomainDeleteModalOpen] = useState <boolean>(false);
  const teamMembersEditing = useSelector(
    (s) => s.teamMembers.dialogs.updateTeamMemberDialog.show
  );

  const showUserEditingDialog = useSelector(
    (s) => s.users.showUserEditingDialog
  );

  const removedTeamMemberPlanId = useSelector(
    (s) => s.teamMembers.dialogs.updateTeamMemberDialog.planId
  );

  const teamMemberId = useSelector<number | undefined>(
    (s) => s.teamMembers.dialogs.updateTeamMemberDialog.user?.id
  );

  const loading = useSelector(
    (s) => s.users.loading.editUser || s.users.loading.editInvitedUser
  );
  const error = useSelector(
    (s) => s.users.errors.editInvitedUser || s.users.errors.editUser
  );
  useLoading({
    loading: loading,
    error: error,
    onSuccess: () => {
      handleClose();
      if (!removedTeamMemberPlanId) {
        if (!!districtId) {
          dispatch(getUsers.request(+districtId));
        } else if (!!currentUserDistrictId) {
          dispatch(getUsers.request(currentUserDistrictId));
        } else {
          dispatch(getUsers.request(undefined));
        }
      }
    },
  });

  const handleDeleteOpenModal = () => {
    setDomainDeleteModalOpen(true);
    };

  const handleClose = () => {
    setWarning(undefined);
    dispatch(hideUserEditingDialog());
    dispatch(hideUpdateTeamMemberDialog());
    setDomainDeleteModalOpen(false); 
  };

  const removeMemberLoading = useSelector(
    (s) => s.teamMembers.loading.deleteTeamMemberFromPlan
  );
  const removeMemberError = useSelector(
    (s) => s.teamMembers.errors.deleteTeamMemberFromPlan
  );
  useLoading({
    loading: removeMemberLoading,
    error: removeMemberError,
    onSuccess: () => {
      handleClose();
    },
  });

  const onRemoveTeamMemberFromPlanConfirm = () => {
    if (removedTeamMemberPlanId && teamMemberId) {
      dispatch(
        deleteTeamMemberFromPlan.request({
          planId: removedTeamMemberPlanId,
          userId: teamMemberId,
        })
      );
    }
  };

  const handleRemoveTeamMemberFromPlan = () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onRemoveTeamMemberFromPlanConfirm();
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "primary",
        text: intl.formatMessage(
          {
            id: "app.confirm-dialog.remove-team-member-from-plan",
          },
          {
            b: (chunks) => <b>{chunks}</b>,
            br: <br />,
          }
        ),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({
          id: "app.confirm-dialog.remove-team-member-from-plan.yes-option",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.confirm-dialog.remove-team-member-from-plan.no-option",
        }),
      })
    );
  };

  const DeleteMemberModal =  useMemo(() => {
    return (
      <Dialog
        className="medium-popup bg-white"
        isOpen={domainDeleteModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="cancel">
          <button onClick={handleClose}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <div className="d-flex justify-center">
          {" "}
          <svg
            width="40"
            height="40"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="24.1016"
              cy="24.2056"
              r="22.458"
              fill="white"
              stroke="black"
              stroke-width="3"
            />
            <path
              d="M22.9715 19.519H25.9955V36.151H22.9715V19.519Z"
              fill="black"
            />
            <circle cx="24.3193" cy="14.0448" r="2.28711" fill="black" />
          </svg>
        </div>

        <p className="remove-team-member">
          {intl.formatMessage(
            {
              id: "app.confirm-dialog.remove-team-member-from-plan",
            },
            {
              b: (chunks) => chunks,
              br: <br />,
            }
          )}
        </p>
        <div className="button-group flex">
          <button onClick={handleClose} className="stroke-btn">
            {intl.formatMessage({
              id: "app.confirm-dialog.remove-team-member-from-plan.no-option",
            })}
          </button>
          <button 
            onClick={() =>onRemoveTeamMemberFromPlanConfirm()}
            className="nj-btn"
          >
            {intl.formatMessage({
              id: "app.confirm-dialog.remove-team-member-from-plan.yes-option",
            })}
          </button>
        </div>
      </Dialog>
    );
  },[domainDeleteModalOpen])

  const form = useMemo(() => "user-editing-form", []);

  const activePlanDistrictId = useSelector(
    (s) => s.plans.activePlan?.district.id
  );
  const handleDialogOpen = () => {
    if (teamMembersEditing && activePlanDistrictId) {
      dispatch(getSchoolsByDistrict.request(activePlanDistrictId));
    }
  };

  return (
    <>
      <Dialog
      className="userEditDialoge"
      isOpen={showUserEditingDialog || teamMembersEditing}
      onOpening={handleDialogOpen}
      onClose={handleClose}
      title={intl.formatMessage({
        id: "app.users-dialog.title",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        {showUserEditingDialog && (
          <p style={{marginBottom:'20px'}}>
            {intl.formatMessage({
              id: "app.users-dialog.edit-user-instructions",
            })}
          </p>
        )}

        <UserEditingForm
          form={form}
          isDistrictSelectorAvailable={isDistrictSelectorAvailable}
          setWarning={setWarning}
          setIsDisabledButton={setIsDisabledButton}
          removedTeamMemberPlanId={removedTeamMemberPlanId}
          teamMemberEditing={teamMembersEditing}
        />
      </div>
      {warning && (
        <div className="mb-3 px-5">
          <div className="warningMsg flex items-center gap-5 mt-1  mb-3">
            <div className="warningIcon d-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="69"
                viewBox="0 0 34 43"
                fill="none"
              >
                <g clip-path="url(#clip0_5381_543)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2675 2.82316C10.4668 2.62156 10.7371 2.5083 11.0188 2.5083H22.9818C23.2636 2.5083 23.5339 2.62156 23.7332 2.82316L31.2099 10.388C31.4092 10.5896 31.5212 10.863 31.5212 11.1481V23.2518C31.5212 23.5369 31.4092 23.8104 31.2099 24.012L23.7332 31.5767C23.5339 31.7784 23.2636 31.8916 22.9818 31.8916H11.0188C10.7371 31.8916 10.4668 31.7784 10.2675 31.5767L2.79069 24.012C2.59144 23.8104 2.47949 23.5369 2.47949 23.2518V11.1481C2.47949 10.863 2.59144 10.5896 2.79069 10.388L10.2675 2.82316ZM11.459 4.6583L4.60449 11.5934V22.8066L11.459 29.7416H22.5418L29.3962 22.8066V11.5934L22.5418 4.6583H11.459Z"
                    fill="#B57F58"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 7.5249C17.5868 7.5249 18.0625 8.0062 18.0625 8.5999V21.4999C18.0625 22.0936 17.5868 22.5749 17 22.5749C16.4132 22.5749 15.9375 22.0936 15.9375 21.4999V8.5999C15.9375 8.0062 16.4132 7.5249 17 7.5249Z"
                    fill="#B57F58"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 24.0083C17.5868 24.0083 18.0625 24.4896 18.0625 25.0833V25.8C18.0625 26.3937 17.5868 26.875 17 26.875C16.4132 26.875 15.9375 26.3937 15.9375 25.8V25.0833C15.9375 24.4896 16.4132 24.0083 17 24.0083Z"
                    fill="#B57F58"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5381_543">
                    <rect width="34" height="43" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="ml-3">
                School Principal and school level staff cannot be designated as District Climate Coordinator (DCC). Please select a district level staff member or yourself for this role.
                </p>
            
            </div>
          </div>
        </div>
      )}
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-end">
          {/* <Button
            className="button-min-width"
            onClick={handleClose}
            disabled={loading}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button> */}
          <div className="space-x-2">
            {!showUserEditingDialog ? (
              <Button
                className="button-min-width btn"
                intent="primary"
                loading={loading || removeMemberLoading}
                onClick={handleDeleteOpenModal}
              >
                {intl.formatMessage({
                  id: "app.dialogs.user-editing-dialog.remove-from-team",
                })}
              </Button>
            ) : (
              <div> </div>
            )}
            <Button
              className="button-min-width btn"
              disabled={isDisabledButton}
              type="submit"
              form={form}
              intent="primary"
              loading={loading || removeMemberLoading}
            >
              {intl.formatMessage({
                id: "app.titles.save",
              })}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
    {DeleteMemberModal}
    </>
  );
};

export default UserEditingDialog;

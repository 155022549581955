import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { EditableText, HTMLTable, Spinner } from "@blueprintjs/core";
import {
  OpenTimePeriod,
  School,
  SurveyDemographic,
  SurveyDeploymentPassword,
} from "../../../types";
import { useIntl } from "react-intl";
import _ from "lodash";
import NoSchools from "../../pages/NoSchools";
import useSchoolPlanDemographics from "../../../helpers/hooks/useSchoolPlanDemographics";
import { getAvailableDemographics } from "../../../helpers/survey-deployment-utils";

type OwnProps = {
  districtIds: number[];
  isGlobal: boolean;
  passwords: Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[];
  setPasswords: React.Dispatch<
    React.SetStateAction<
      Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
    >
  >;
  isTeamPlanDialog?: boolean;
  schools: School[];
  openTimePeriods: OpenTimePeriod[];
  passwordData?:any
};

type Props = OwnProps;

const DEFAULT_PASSWORDS: { [key in SurveyDemographic]: string } = {
  [SurveyDemographic.ElementaryStudents]: "student35",
  [SurveyDemographic.Students]: `student612`,
  [SurveyDemographic.SchoolStaff]: "staff",
  [SurveyDemographic.Parents]: "parent",
};

const SurveyDeploymentPasswords: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const {
    passwords,
    setPasswords,
    schools,
    isTeamPlanDialog,
    openTimePeriods,
    passwordData
  } = props;

  const schoolsDictionary: {
    [school: number]: School;
  } = useMemo(() => {
    return _.chain(schools)
      .keyBy((s) => s.id!)
      .value();
  }, [schools]);

  const activePlanSchool = useSelector((s) => s.plans.activePlan?.school);
  const { demographics } = useSchoolPlanDemographics({
    grades: isTeamPlanDialog ? activePlanSchool?.grades : undefined,
  });

  const availableDemographics = useMemo(
    () =>
      getAvailableDemographics({
        availableDemographicsInPlan: demographics,
        schoolOpenPeriods: openTimePeriods,
      }),
    [demographics, openTimePeriods]
  );

  useEffect(() => {
    const missingPasswords = schools.flatMap((school) =>
      availableDemographics
        .filter((demographic) =>
          // Check if this demographic and school combo already has a password
          !passwords.some(
            (pass) =>
              pass.school === school.id && pass.demographic === demographic
          )
        )
        .map((demographic) => {
          const defaultPassword = DEFAULT_PASSWORDS[demographic];
          return {
            id: +_.uniqueId() * -1, // Assign unique local ID
            school: school.id,
            demographic: demographic,
            password: defaultPassword,
          };
        })
    );
  
    // Merge missing passwords with existing ones, ensuring uniqueness
    const updatedPasswords = _.uniqBy(
      [...passwords, ...missingPasswords],
      (entry) => `${entry.school}-${entry.demographic}`
    );
  
    // Update the state only if there are changes
    if (updatedPasswords.length !== passwords.length) {
      setPasswords(updatedPasswords);
    }
  }, [schools, availableDemographics, passwords, setPasswords]);
   

  const loading = useSelector(
    (s) =>
      s.schools.loading.getSchoolsByDistricts || s.schools.loading.getSchools
  );

  const handleConfirmSurveyPasswordChange = (surveyPasswordId: number) => (
    value: string
  ) => {
    setPasswords((passwords) =>
      passwords.map((p) =>
        p.id === surveyPasswordId ? { ...p, password: value } : p
      )
    );
  };

  return (
    <div>
      

      <div className="mb-4">
        
        <HTMLTable
          className="w-10 table-fixed mt-3 bg-white  "
          // striped
          // bordered
          condensed
        >
         
          <tbody>
  {loading ? (
    <tr>
      <td colSpan={3}>
        <Spinner intent="primary" />
      </td>
    </tr>
  ) : schools.length ? (
    passwords
      .filter((pass) => passwordData === pass.demographic) // Only relevant passwords
      .map((pass,index) => {
        return index===0&&<div key={pass.id} style={{width:"70px"}}>

            <EditableText
              className="w-20 h-8"
              defaultValue={pass.password}
              onConfirm={handleConfirmSurveyPasswordChange(pass.id)}
              maxLength={25}
            />
       
        </div>
})
  ) : (
    <tr>
      <td colSpan={3}>
        <NoSchools hasAction={false} />
      </td>
    </tr>
  )}
</tbody>

        </HTMLTable>
      </div>
    </div>
  );
};

export default SurveyDeploymentPasswords;
